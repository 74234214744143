import { Container } from '@mui/material';

import SearchPanel from '../components/searchPanel/SearchPanel';
import SearchTabs from '../components/searchTabs/SearchTabs';
import useTriggerOnceOnMount from '../hooks/useTriggerOnceOnMount';
import { getUserRole } from '../services/auth.service';
import { PageType, setPageViewEvent } from '../utils/pendoAnalytics';

function SearchResultsPage(): JSX.Element {
  const role = getUserRole();

  useTriggerOnceOnMount(() => setPageViewEvent(PageType.SearchResult, role));

  return (
    <Container maxWidth="lg" className="minContainer">
      <SearchPanel />
      <SearchTabs />
    </Container>
  );
}

export default SearchResultsPage;
