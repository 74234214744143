import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { TabClickEvent, setTabEvent } from '../utils/pendoAnalytics';

const HashToTabClickEvent = {
  products: 'Products',
  manufacturers: 'Manufacturers',
  areas: 'Therapeutic',
};

const useSetTabEventToAnalytics = (): void => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      setTabEvent(
        TabClickEvent[
          HashToTabClickEvent[hash.slice(1) as keyof typeof HashToTabClickEvent] as keyof typeof TabClickEvent
        ]
      );
    }
  }, [hash]);
};

export default useSetTabEventToAnalytics;
