import jwtDecode from 'jwt-decode';

import { FORBIDDEN_STATUS_CODE } from '../components/components.constant';
import configuration from '../config';
import { isExpectedStatusCode } from '../utils/shouldErrorBeDisplayed';

export interface JWTPayload extends Partial<Record<string, unknown>> {
  username: string;
  firstName: string;
  lastName: string;
  refreshToken: string;
  otcAccessAllowed: boolean;
  userRole: string;
  exp: number;
  iat: number;
}

export const getApiToken = (): string | null => localStorage.getItem('token');
export const setApiToken = (token: string): void => localStorage.setItem('token', token);

export const getWebToken = (): string | null => localStorage.getItem('webToken');
export const setWebToken = (token: string): void => localStorage.setItem('webToken', token);
export const removeWebToken = (): void => localStorage.removeItem('webToken');

export const getNotAllowedFlag = (): string | null => localStorage.getItem('notAllowed');
export const setNotAllowedFlag = (): void => localStorage.setItem('notAllowed', 'true');
export const removeNotAllowedFlag = (): void => localStorage.removeItem('notAllowed');

export const getRefreshToken = (): string | null => {
  const webToken = getWebToken();

  if (webToken) {
    const { refreshToken } = jwtDecode(webToken) as JWTPayload;

    return refreshToken;
  }

  return null;
};

export const redirectToErrorPage = (statusCode: number | undefined): void => {
  if (statusCode) {
    if (isExpectedStatusCode(statusCode)) {
      window.location.href = `/error?statusCode=${statusCode}`;
    }
  } else {
    redirectToLoginPage();
  }
};

export const redirectToLoginPage = (): void => {
  window.location.replace(configuration.REACT_APP_AUTH_URL);
};

export const validateToken = (token: string): void => {
  const { otcAccessAllowed = false } = jwtDecode(token) as JWTPayload;

  if (otcAccessAllowed) {
    removeNotAllowedFlag();
    setWebToken(token);
    initializePendo();
  } else {
    setNotAllowedFlag();
    redirectToErrorPage(FORBIDDEN_STATUS_CODE);
  }
};

export const getUserRole = (): string => {
  const token = getWebToken();

  return token ? (jwtDecode(token) as JWTPayload).userRole : 'unknown';
};

export const getUserDetails = (): { tokenDetails: JWTPayload | null } => {
  const token = getWebToken(); // Assume getWebToken fetches the JWT token

  if (token) {
    const decodedToken = jwtDecode<JWTPayload>(token); // Decode the token

    return {
      tokenDetails: decodedToken,
    };
  }

  // Return null if no token is found
  return {
    tokenDetails: null,
  };
};

// Pendo initialization function
const initializePendo = (): void => {
  const userDetails = getUserDetails();

  if (window.pendo) {
    window.pendo.initialize({
      visitor: {
        id: userDetails.tokenDetails?.username,
        firstName: userDetails.tokenDetails?.firstName,
        lastName: userDetails.tokenDetails?.lastName,
        appName: 'GuideToOtc',
      },
      account: {
        id: userDetails.tokenDetails?.username,
        role: getUserRole,
      },
    });
  }
};
