declare global {
  interface Window {
    pendo: any;
  }
}

export enum TabClickEvent {
  Products = 'click.products',
  Manufacturers = 'click.manufacturers',
  Therapeutic = 'click.therapeutic',
}

export enum TherapeuticAreaTabClickEvent {
  Overview = 'click.therapeutic.overview',
  Products = 'click.therapeutic.product',
}

export enum PageType {
  Home = 'Home page',
  SearchResult = 'Search result page',
  TherapeuticArea = 'Therapeutic Area page',
  ProductDetails = 'Product details page',
  ErrorPage = 'Error Page',
}

export const setPageViewEvent = (pageType: PageType, role?: string): void => {
  // Track page view in Pendo
  if (typeof window.pendo !== 'undefined') {
    window.pendo.track('page.view', {
      area: 'GuideToOtc',
      page_type: pageType,
      login_type: role,
    });
  }
};

export const setSearchEvent = (
  searchTerm: string,
  numberOfProducts: number,
  numberOfManufacturers: number,
  numberOfTherapeutic: number
): void => {
  // Track search event in Pendo
  if (typeof window.pendo !== 'undefined') {
    window.pendo.track('search.button.click', {
      search_term: searchTerm,
      products_quantity: numberOfProducts,
      manufacturers_quantity: numberOfManufacturers,
      therapeutic_quantity: numberOfTherapeutic,
    });
  }
};

export const setTabEvent = (eventType: TabClickEvent): void => {
  // Track tab click event in Pendo
  if (typeof window.pendo !== 'undefined') {
    window.pendo.track(eventType); // Event type is passed directly
  }
};

export const setTherapeuticAreaTabEvent = (eventType: TherapeuticAreaTabClickEvent, area: string): void => {
  // Track therapeutic area tab click in Pendo
  if (typeof window.pendo !== 'undefined') {
    window.pendo.track(eventType, {
      therapeutic_area: area,
    });
  }
};
