import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { getUserRole } from '../services/auth.service';
import { PageType, setPageViewEvent } from '../utils/pendoAnalytics';

const useSetPageEventOnIdChange = (pageType: PageType): void => {
  const { id } = useParams();
  const ref = useRef('');

  useEffect(() => {
    if (id && ref.current !== id) {
      ref.current = id;
      const role = getUserRole();

      setPageViewEvent(pageType, role);
    }
  }, [id, pageType]); // Include activeGroups in the dependency array
};

export default useSetPageEventOnIdChange;
