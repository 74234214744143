import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { setTherapeuticAreaTabEvent, TherapeuticAreaTabClickEvent } from '../utils/pendoAnalytics';

const HashToTherapeuticAreaTabClickEvent = {
  overview: 'Overview',
  products: 'Products',
};

const useSetTherapeuticAreaTabToAnalytics = (area: string): void => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash && area) {
      setTherapeuticAreaTabEvent(
        TherapeuticAreaTabClickEvent[
          HashToTherapeuticAreaTabClickEvent[
            hash.slice(1) as keyof typeof HashToTherapeuticAreaTabClickEvent
          ] as keyof typeof TherapeuticAreaTabClickEvent
        ],
        area
      );
    }
  }, [area, hash]);
};

export default useSetTherapeuticAreaTabToAnalytics;
